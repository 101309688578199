<template>
  <ui-transition>
    <div v-if="!isLoading">
      <router-link
        v-for="list in collection"
        :key="list.id"
        :to="`/${$route.name === 'DocumentSelection' ? 'documents' : 'todo'}/${list.id}`"
        class="bg-level-1 rounded-xl shadow-xl px-4 py-3 mb-3 active:bg-level-3 flex items-center"
      >
        <div class="flex-grow mr-4">
          <h2 class="text-main">
            {{ list.name }}
          </h2>
          <p class="text-sub text-xs">
            {{ list.summary }}
          </p>
        </div>
        <div class="flex-shrink-0">
          <font-awesome-icon class="text-sub text-lg" :icon="['fas', 'chevron-right']" />
        </div>
      </router-link>
    </div>
  </ui-transition>
</template>

<script>
import TodoService from '../services/TodoService'

export default {
  name: 'TodoSelection',
  data: () => ({
    isLoading: true,
    collection: [],
  }),
  created() {
    this.fetch()
  },
  methods: {
    async fetch() {
      this.isLoading = true
      this.collection = await (this.$route.name === 'DocumentSelection'
        ? TodoService.getDocuments()
        : TodoService.getCollection())
      this.isLoading = false
    },
  },
}
</script>
