<template>
  <div>
    <ui-transition>
      <article v-if="!isLoading">
        <section class="bg-level-1 shadow-xl rounded-xl px-4 py-3 mb-4">
          <h2 class="font-bold text-main">
            {{ todo.name }}
          </h2>
          <template v-if="isTodo">
            <ui-progress :value="checked" :max="all" />
            <div class="flex items-center justify-between mt-4">
              <div class="text-sub text-xs">
                未完了のタスクのみ表示
              </div>
              <ui-switch v-model="showOnlyIncompletedTasks" />
            </div>
            <ui-button class="text-xs w-full mt-4" color="error" @click="clearConfirmDialog = true">
              全てクリア
            </ui-button>
          </template>
        </section>
        <section v-for="list in todo.todo_lists" :key="list.id" class="mb-6 px-1">
          <transition
            enter-active-class="ease-out transform duration-100"
            enter-class="opacity-0 scale-110"
            enter-to-class="opacity-100 scale-100"
            leave-active-class="ease-in transform duration-100 delay-200"
            leave-class="opacity-100 scale-100"
            leave-to-class="opacity-0 scale-90"
          >
            <div
              v-if="
                list.todo_items.length &&
                  (!showOnlyIncompletedTasks || list.todo_items.some(item => !item.checked))
              "
              class="mb-6 px-1 todo-item-container"
            >
              <h2
                class="text-sm font-bold text-main mb-3 border-b border-gray-600 py-2 border-opacity-50"
              >
                {{ list.name }}
              </h2>
              <div v-for="item in list.todo_items" :key="item.id">
                <todo-item
                  :class="
                    activeId === item.id
                      ? 'bg-yellow-200 bg-opacity-25 -mx-8 px-8 py-1 my-2 font-bold'
                      : ''
                  "
                  :item="item"
                  :hide-if-checked="showOnlyIncompletedTasks"
                  @toggle-checkbox="toggleCheckbox(list.id, item.id, $event)"
                />
              </div>
            </div>
          </transition>
        </section>
      </article>
    </ui-transition>
    <ui-dialog
      v-model="clearConfirmDialog"
      title="確認"
      type="error"
      description="全てのチェックボックスを未チェック状態にします。よろしいですか？"
    >
      <ui-button color="error" class="mb-2" @click="clearCheckboxes">
        全てクリア
      </ui-button>
      <ui-button @click="clearConfirmDialog = false">
        キャンセル
      </ui-button>
    </ui-dialog>
  </div>
</template>

<script>
import TodoService from '../services/TodoService'
import TodoItem from '../components/todo/TodoItem.vue'

export default {
  name: 'Todo',
  components: {
    TodoItem,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    isLoading: true,
    todo: [],
    ws: null,
    activeId: null,
    wsHealthCheckTimer: null,
    wsReconnectTimer: null,
    clearConfirmDialog: false,
  }),
  computed: {
    all() {
      return this.todo.todo_lists.reduce((p, c) => p + c.todo_items.length, 0)
    },
    checked() {
      return this.todo.todo_lists.reduce(
        (p, c) => p + c.todo_items.filter(item => item.checked).length,
        0,
      )
    },
    showOnlyIncompletedTasks: {
      get() {
        return this.$store.getters['app/todoShowOnlyIncompletedTasks']
      },
      set(value) {
        this.$store.commit('app/todoShowOnlyIncompletedTasks', value)
      },
    },
    isTodo() {
      return this.$route.name === 'Todo'
    },
    isDocument() {
      return this.$route.name === 'Document'
    },
  },
  async mounted() {
    await Promise.all([this.subscribe(), this.fetch()])
    await this.$nextTick()
    this.$moduloBox.getGalleries()
    if (location.hash.includes('?c=')) {
      this.activeId = parseInt(location.hash.split('?c=')[1], 10)
      const scrollTarget = document.getElementById(`c${this.activeId}`)
      if (scrollTarget) {
        scrollTarget.scrollIntoView()
      }
    }
  },
  updated() {
    this.$nextTick(() => {
      this.$moduloBox.getGalleries()
    })
  },
  beforeDestroy() {
    this.stopWsReconnectTimer()
    this.stopWsHealthCheckTimer()
    this.ws.close()
  },
  methods: {
    async fetch() {
      this.isLoading = true
      this.todo = await TodoService.getAllTodoItems(this.id)
      this.isLoading = false
    },
    async silentFetch() {
      this.todo = await TodoService.getAllTodoItems(this.id)
    },
    async toggleCheckbox(listId, itemId, checked) {
      await TodoService.toggleCheckbox({
        deviceId: this.$deviceId,
        collectionId: this.todo.id,
        listId,
        itemId,
        checked,
      })
    },
    async clearCheckboxes() {
      this.clearConfirmDialog = false
      await TodoService.clearCheckbox({
        deviceId: this.$deviceId,
        collectionId: this.todo.id,
      })
      this.silentFetch()
    },
    subscribe() {
      return new Promise(resolve => {
        this.ws = new WebSocket('wss://fmapp.net/ws/connection')
        this.ws.onopen = () => resolve()
        this.ws.onmessage = event => {
          if (event.data === 'pong') {
            this.stopWsReconnectTimer()
            return
          }
          const data = JSON.parse(event.data)
          if (data.deviceId === this.$deviceId) return
          if (data.app !== 'todo') return
          if (data.collectionId !== this.todo.id) return
          if (data.clear) {
            this.silentFetch()
            return
          }
          const targetList = this.todo.todo_lists.find(list => list.id === data.listId)
          if (!targetList) return
          const targetItem = targetList.todo_items.find(item => item.id === data.itemId)
          if (!targetItem) return
          this.$set(targetItem, 'checked', data.checked)
        }

        this.wsHealthCheckTimer = setInterval(() => {
          this.ws.send('ping')
          this.startWsReconnectTimer()
        }, 10000)
      })
    },
    startWsReconnectTimer() {
      this.wsReconnectTimer = setTimeout(() => {
        this.stopWsHealthCheckTimer()
        this.ws.close()
        this.subscribe()
        this.silentFetch()
      }, 3000)
    },
    stopWsReconnectTimer() {
      clearTimeout(this.wsReconnectTimer)
    },
    stopWsHealthCheckTimer() {
      clearInterval(this.wsHealthCheckTimer)
    },
  },
}
</script>
