<template>
  <transition
    enter-active-class="ease-out transform duration-100"
    enter-class="opacity-0 scale-110"
    enter-to-class="opacity-100 scale-100"
    leave-active-class="ease-in transform duration-100 delay-200"
    leave-class="opacity-100 scale-100"
    leave-to-class="opacity-0 scale-90"
  >
    <div
      v-show="!hideIfChecked || !(checkedStatus === undefined ? item.checked : checkedStatus)"
      :id="`c${item.id}`"
    >
      <p v-if="isDocument" class="text-sm mb-1 flex items-start">
        <font-awesome-icon
          :icon="['fas', 'circle']"
          class="mt-1 text-sub"
          style="font-size: 10px"
        />
        <span class="ml-2 text-main">{{ item.name }}</span>
      </p>
      <ui-checkbox
        v-else
        :value="checkedStatus === undefined ? item.checked : checkedStatus"
        class="text-sm mb-1"
        :label="item.name"
        :label-class="item.emphasized ? 'text-red-400 font-bold' : ''"
        @change="$emit('toggle-checkbox', $event)"
      />
      <div v-if="item.description">
        <!-- eslint-disable vue/no-v-html -->
        <div
          class="py-2 px-3 mb-2 mx-auto ml-5 text-sm text-indigo-300 text-opacity-75 rounded-lg bg-level-3"
          v-html="escapedAndLinkConverted(item.description)"
        />
        <!-- eslint-enable -->
      </div>
      <a
        v-if="item.attached_image"
        class="mobx"
        :href="`/uploads/todo/${item.attached_image}`"
        :data-title="item.name"
      >
        <img
          v-lazy="`/uploads/todo/${item.attached_image}`"
          class="rounded-xl shadow-xl mb-4 mx-auto w-10/12"
        />
      </a>
    </div>
  </transition>
</template>

<script>
import sanitizeHtml from 'sanitize-html'

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    checkedStatus: {
      type: Boolean,
      default: undefined,
    },
    hideIfChecked: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isDocument() {
      return this.$route.name === 'Document'
    },
  },
  methods: {
    escapedAndLinkConverted(text) {
      const escaped = sanitizeHtml(text, {
        allowedTags: [],
        allowedAttributes: {},
        disallowedTagsMode: 'escape',
      })
      const urlRegExp = /(https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*))/gi
      return escaped
        .split('\n')
        .map(s => `<p style="min-height: 21px;">${s}</p>`)
        .join('')
        .replace(
          urlRegExp,
          '<span class="inline-block truncate w-full"><a class="text-purple-400 underline" href="$1" target="_blank">$1</a></span>',
        )
    },
  },
}
</script>
